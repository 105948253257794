import React, {ReactNode} from 'react';
import FadeTransition from "./FadeTransition";
import {connect} from "react-redux";

type BaseTooltipErrorProps = {
    name: string,
    id?: string,
    className: string,
    showErrors: boolean,
    tooltipErrors: object,
    children: ReactNode
};
type BaseTooltipErrorState = {};

class BaseTooltipError extends React.PureComponent<BaseTooltipErrorProps,BaseTooltipErrorState> {

    render() {
        const name = this.props.name;
        // @ts-ignore
        const error = this.props.tooltipErrors[name];
        return (
            <div id={this.props.id} className={this.props.className}>
                <div style={{position: 'relative', width: '100%'}}>
                    <FadeTransition timeout={1003} in={error !== undefined && this.props.showErrors}>
                        <div className="tooltip-error base">{error}</div>
                    </FadeTransition>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

// @ts-ignore
const mapStateToProps = state => ({
    tooltipErrors: state.base.tooltipErrors,
    showErrors: state.base.showErrors,
});

export default connect(mapStateToProps, {})(BaseTooltipError);

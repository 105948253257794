import React, { FC, ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setPriceAlertEmail } from '../../../actions/baseActions';
import ApiInterface from '../../modules/ApiInterface';
import Helper from '../../modules/Helper';
import Trans from './Trans';

type NewsLetterFormProps = {
	enabledVert: string;
	lng: string;
	children: ReactNode;
	countryCode: string;
	isUserLoggedIn: boolean;
	setPriceAlertEmail: (email: string) => void;
};

const NewsLetterForm: FC<NewsLetterFormProps> = (props) => {
	const { enabledVert, lng, setPriceAlertEmail, children } = props;

	const [email, setEmail] = useState('');
	const [disabled, setDisabled] = useState(false);
	const { t } = useTranslation();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const onSubmitForm = (e: React.FormEvent) => {
		e.preventDefault();

		if (disabled) {
			return;
		}
		if (!Helper.isValidEmail(email)) {
			return;
		}
		const params = new URLSearchParams();
		params.append('user-id', email);
		params.append('lng', lng);
		setPriceAlertEmail(email);
		ApiInterface.instance
			.newsletterSubscription(enabledVert, params)
			.then(() => {
				setEmail('');
				setDisabled(true);
			})
			.catch((err: any) => {});
	};

	return (
		<div id="newsletter-form">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-11">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-5  mb-3 mt-5  text-start">
								<h4>
									<Trans i18nKey="newsletter.title" />
								</h4>
								{children}
							</div>

							<div className="col-lg-7 text-center">
								<form onSubmit={onSubmitForm} className="row justify-content-center" action="#">
									<div className="col-lg-9 col-md-7 col-12">
										<div className="form-group flex-1 mb-0" style={{ position: 'relative' }}>
											<input
												required
												value={email}
												onChange={onChange}
												disabled={disabled}
												placeholder={disabled ? t('newsletter.successMessage') : t('newsletter.inputPlaceholder')}
												type="email"
												className="form-control"
												id="email"
												style={{ width: '100%' }}
											/>
										</div>
									</div>
									<div className="col-lg-3 col-md-5 col-12 mt-4 mt-md-0">
										<Button
											disabled={disabled}
											style={{ width: '100%' }}
											type="submit"
											variant="dark"
											className="btn btn-black border-0"
										>
											<Trans i18nKey="keywords.signUp" />
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
// @ts-ignore
const mapStateToProps = (state) => ({
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
	countryCode: state.base.countryCode,
	isUserLoggedIn: state.base.isUserLoggedIn,
});
export default React.memo(connect(mapStateToProps, { setPriceAlertEmail })(NewsLetterForm));

import React, { ChangeEvent } from 'react';
import { connect } from "react-redux";
import { setPriceAlertEmail, togglePriceAlertHandler } from "../../../actions/baseActions";
import Helper from "../../modules/Helper";
import BaseTooltipError from "../Common/BaseTooltipError";
// @ts-ignore
import Checkbox from "../Common/Checkbox";

type EmailConfirmationCheckboxProps = {
    setPriceAlertEmail: (email: string) => void
    togglePriceAlertHandler: (toggle: boolean) => void
    email: string
    isDesktop: boolean
};
type EmailConfirmationCheckboxState = {
    checked: boolean
};

class EmailConfirmationCheckbox extends React.Component<EmailConfirmationCheckboxProps, EmailConfirmationCheckboxState> {
    constructor(props: EmailConfirmationCheckboxProps) {
        super(props);
        this.state = {
            checked: false
        }
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.setPriceAlertEmail(e.target.value);
    };
    handleChange = (checked: boolean) => {
        this.props.togglePriceAlertHandler(checked);
        this.setState({checked})
    };
    getEmailInput = () => {
        return (
           <div className="position-relative">
               <div                style={{
                   position: 'absolute',
                   top: '13px',
                   left: '10px'
               }}>
                   <img src="/images/email-o.svg" alt=""/>

               </div>

               <input

                   placeholder={Helper.trans('priceAlert.emailPlaceholder')}
                   autoComplete={"email"}
                   name="email"
                   onChange={this.onChange}
                   value={this.props.email}
                   type="email" className="form-control cs-input"/>

           </div>
        );
    }

    render() {
        const isDesktop = this.props.isDesktop;
        const checked = this.state.checked;

        // if (isMobile) {
            return (
                <>
                    <div>

                        <Checkbox checked={checked}
                                  label={Helper.trans('priceAlert.label')}
                                  id={"price-alert-checkbox-confirmation"}
                                  onChange={() => this.handleChange(!checked)}/>
                    </div>
                    {!isDesktop  && this.state.checked? (
                        <div className="col-12">
                            <div className="row">
                                <BaseTooltipError className={"col-md-12"} name={"price-alert-email"}>
                                    {this.getEmailInput()}
                                </BaseTooltipError>
                            </div>

                        </div>
                    ) : ''}

                </>
            )
    }

}

// @ts-ignore
const mapStateToProps = state => ({
    email: state.base.priceAlertEmail
});
export default connect(mapStateToProps, {setPriceAlertEmail, togglePriceAlertHandler})(EmailConfirmationCheckbox);
import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sendDataToPassthruForm } from '../../../../actions/baseActions';
import {
	doSearchHotels,
	doSearchPartners,
	prePopulateDataFromQueryString,
	submitFormBack,
	submitFormFront,
} from '../../../../actions/hotelActions';
import Helper from '../../../modules/Helper';
import Checkbox from '../../Common/Checkbox';
import CompanyImage from '../../Common/CompanyImage';
import OvalSpinner from '../../Common/Loaders/OvalSpinner';
import TooltipError from '../../Common/TooltipError';
import Confirmation from '../../PriceAlert/Confirmation';
import ModalSignUp from '../../PriceAlert/ModalSignUp';
import CheckInDate from '../CheckInDate';
import CheckOutDate from '../CheckOutDate';
import { companies } from '../Companies';
import HotelDestination from '../HotelDestination';
import Rooms from '../Rooms';

function KCFilterForm(props) {
	const portalRef = useRef(null);

	const { t } = useTranslation();
	const getSearchButtonText = () => {
		if (props.compare) {
			return 'keywords.compare';
		}
		return props.priceAlertHandlerChecked ? 'keywords.signUpAndSearch' : 'main.hotels.searchBtn';
	};

	const arePartnersLoading = useMemo(() => {
		return props.compareResult === null;
	}, [props.compareResult]);
	const arePartnersLoaded = useMemo(() => {
		return props.compareResult?.kayakResponse != null;
	}, [props.compareResult]);
	const PARTNER_DISPLAY_LIMIT = 10;
	const partners = useMemo(() => {
		const items = props.compareResult?.kayakResponse?.compareToItems || [];
		return items.slice(0, PARTNER_DISPLAY_LIMIT);
	}, [props.compareResult]);
	const domain = useMemo(() => {
		return props.compareResult?.kayakDomain;
	}, [props.compareResult]);
	const preparedPartners = useMemo(() => {
		const makeLink = (link) => {
			if (link.startsWith('http')) {
				return link;
			}
			return 'https://' + domain + link;
		};
		if (!arePartnersLoaded) {
			return null;
		}
		return partners.map((partner) => {
			return {
				...partner,
				deepLink: makeLink(partner.deepLink),
				logoUrl: makeLink(partner.logoUrl),
			};
		});
	}, [arePartnersLoaded, partners, domain]);

	const [partnerCheckboxStatuses, setPartnerCheckboxStatuses] = useState([]);
	useEffect(() => {
		if (arePartnersLoading) {
			setPartnerCheckboxStatuses([]);
		}
		if (arePartnersLoaded) {
			setPartnerCheckboxStatuses(partners.map(() => false));
		}
	}, [arePartnersLoading, arePartnersLoaded]);

	const PARTNER_LIMIT = 4;
	const checkChoiceCountLimit = useMemo(() => {
		const trueCount = partnerCheckboxStatuses.filter((status) => status).length;
		return trueCount >= PARTNER_LIMIT;
	}, [partnerCheckboxStatuses]);
	const setPartnerChecked = (partnerId) => {
		setPartnerCheckboxStatuses((prev) => {
			const newStatuses = [...prev];
			newStatuses[partnerId] = !prev[partnerId];
			if (checkChoiceCountLimit && newStatuses[partnerId]) {
				return prev;
			}
			return newStatuses;
		});
	};
	const generateCompareUrl = (data) => {
		return '/' + props.enabledVert + '/compare-result?' + Helper.serialize(data);
	};

	const searchId = useMemo(() => {
		return props.compareResult?.kayakResponse?.searchId;
	}, [props.compareResult]);
	const cluster = useMemo(() => {
		const cookies = props.compareResult?.kayakCookies;
		if (!cookies) {
			return null;
		}
		const k = 'cluster=';
		const clusterCookie = cookies.find((cookie) => cookie.includes(k));
		return clusterCookie?.split(k)[1][0];
	}, [props.compareResult?.kayakCookies]);
	const generateCompareUrlMultiSelect = (indexes) => {
		const providerCodes = indexes.map((index) => partners[index].providerCode);
		const joinedProviderCodes = providerCodes.join(',');
		const showOn = 'panel';
		const requestParams = {
			providerCodes: joinedProviderCodes,
			showOn,
			searchId,
		};
		const iframeUrl = '/providers/simple-window?' + Helper.serialize(requestParams);
		const affliateId = 'kan_243435_561323';
		const p = `${props.uid}${props.imid}`;
		const fullUrl = `https://www.kayak.com/in?a=${affliateId}&cluster=${cluster}&p=${p}&url=${iframeUrl}`;
		return fullUrl;
	};

	const openInNewTab = (url) => {
		window.open(url);
	};
	const goToResultPage = (checkboxes) => {
		let indexes = checkboxes.map((checked, index) => (checked ? index : null)).filter((index) => index !== null);
		if (!indexes.length) {
			const partnersCount = partners.length;
			indexes = [0, 1, 2, 3].slice(0, partnersCount);
		}
		const parsed = props.compareData;
		const newData = {
			...parsed,
			ids: indexes,
		};
		const redirectUrl = generateCompareUrlMultiSelect(indexes);
		if (Helper.isKayakCompareFlowFront()) {
			console.log('Opening new tab with data: ', newData);
			openInNewTab(redirectUrl);
			console.log('submitting form back');
			props.submitFormBack(parsed);
		} else if (Helper.isKayakCompareFlowBack()) {
			console.log('Redirecting to compare-result: ', newData);
			window.location = redirectUrl;
		}
	};
	const [isSearchButtonHidden, setIsSearchButtonHidden] = useState(false);
	const searchPartners = () => {
		const isKcForm = true;
		setIsSearchButtonHidden(true);
		props.doSearchPartners(isKcForm);
		if (!Helper.isKayakCompareFlowFront()) {
			props.submitFormFront();
		}
	};
	useEffect(() => {
		setIsSearchButtonHidden(false);
	}, [props.openedInput]);

	return (
		<div className="container" id="kayak-compare-filter-form">
			<div className="row">
				<div className="col-7" id="parent-filter">
					<div id="filter-form" className="row justify-content-center">
						<br className="d-xl-block d-none" />
						<form
							id="redirect-back-form"
							method="post"
							action={process.env.NEXT_PUBLIC_BACKEND_URL + '/hotels/redirect'}
						/>
						<div className="px-4">
							<div>
								<TooltipError flexOne name="hotel-destination">
									<HotelDestination name="hotel-destination" />
								</TooltipError>
							</div>
							<div className="row">
								<div className="col-6">
									<TooltipError flexOne name="check-in-date">
										<CheckInDate name="check-in-date" />
									</TooltipError>
								</div>
								<div className="col-6">
									<TooltipError flexOne name="check-out-date">
										<CheckOutDate name="check-out-date" />
									</TooltipError>
								</div>
							</div>
							<div className="position-relative" id="room-type" ref={portalRef}>
								<TooltipError flexOne name="room-type">
									<Rooms name="room-type" roomsPortalRef={portalRef} />
								</TooltipError>
							</div>
							<Confirmation />
							{!isSearchButtonHidden && (
								<div id="search-button-container" className="col-12 mt-2">
									<button
										id="search-button"
										type="button"
										onClick={() => searchPartners()}
										className={classnames('btn', {
											'price-alert-handler-checked': props.priceAlertHandlerChecked,
										})}
									>
										{t(getSearchButtonText())}
									</button>
								</div>
							)}
						</div>
						<ModalSignUp />
					</div>
				</div>
				<div
					className={classnames('col-5 brands-section px-4 pb-4', {
						'brands-selection': arePartnersLoaded,
					})}
				>
					{arePartnersLoaded ? (
						<div>
							<h6>{t('compare.choose')}</h6>
							{preparedPartners.map((partner, key) => (
								<div key={key} className="company-item row gx-2 mt-2">
									<div className="col-2">
										<div
											className={classnames('c-checkbox-compare', {
												'd-none': checkChoiceCountLimit && !partnerCheckboxStatuses[key],
											})}
										>
											<Checkbox
												id={`compare-${key}-checkbox`}
												checked={!!partnerCheckboxStatuses[key]}
												onChange={() => setPartnerChecked(key)}
											/>
										</div>
									</div>

									<div onClick={() => setPartnerChecked(key)} className="col-5" style={{ cursor: 'pointer', flex: 1 }}>
										<div className="comp-logo">
											<img src={partner.logoUrl} className="w-100" />
										</div>
									</div>
									<div onClick={() => openInNewTab(partner.deepLink)} className={classnames('col-5', {})}>
										<div className="view-btn" style={{ cursor: 'pointer', flex: 1 }}>
											{t('keywords.view')}
										</div>
									</div>
								</div>
							))}
							<div id="compare-button-container" className="col-12 mt-4">
								<button
									id="compare-button"
									type="button"
									onClick={() => goToResultPage(partnerCheckboxStatuses)}
									className={classnames('btn', {
										'price-alert-handler-checked': props.priceAlertHandlerChecked,
									})}
								>
									{t('keywords.compare')}
								</button>
							</div>
						</div>
					) : (
						<>
							<h6 className="text-center">{props.brandsDescription}</h6>
							<div className="text-center justify-content-center row">
								{arePartnersLoading ? (
									<OvalSpinner
										height={230}
										width={230}
										color="#049dd9"
										wrapperStyle={{}}
										wrapperClass=""
										visible={true}
										ariaLabel="oval-loading"
										secondaryColor="#696969"
										strokeWidth={3}
										strokeWidthSecondary={3}
									/>
								) : (
									companies.map((companyImage, key) => {
										return (
											<div key={key} className="col-2 brand-img">
												<CompanyImage src={companyImage} />
											</div>
										);
									})
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	openedInput: state.base.openedInput,
	countryCode: state.base.countryCode,
	compareResult: state.compare.result,
	compareData: state.compare.data,
	enabledVert: state.base.enabledVert,
	uid: state.base.uid,
	imid: state.base.imid,
});

export default connect(mapStateToProps, {
	doSearchHotels,
	doSearchPartners,
	prePopulateDataFromQueryString,
	submitFormBack,
	submitFormFront,
	sendDataToPassthruForm,
})(KCFilterForm);

import React from 'react';
import { connect } from 'react-redux';
import Helper from '../../modules/Helper';
import EmailConfirmation from './EmailConfirmation';
import EmailConfirmationCheckbox from './EmailConfirmationCheckbox';

type ConfirmationProps = {
	onlyMobile?: boolean;
	showCheckbox?: boolean;
	isBrowserSupportNotification?: boolean;
	hideOnMobile?: boolean;
	onlyDesktop?: boolean;
	priceAlert: string[];
	countryCode: string;
};
type ConfirmationState = {
	isMobile: boolean;
};
class Confirmation extends React.Component<ConfirmationProps, ConfirmationState> {
	constructor(props: ConfirmationProps) {
		super(props);
		this.state = {
			isMobile: false,
		};
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
	}

	componentDidMount() {
		if (typeof window != 'undefined') {
			this.checkWindowSize();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkWindowSize);
	}

	checkWindowSize = () => {
		const width = window.innerWidth;
		this.setState({
			isMobile: width < 1200,
		});
	};

	render() {
		if (typeof window == 'undefined') {
			return <></>;
		}
		// const supportedCountryCodes = new Set(['US' ,'UK' , 'CA' ,'AU' , 'NZ', 'CZ']);
		const isMobile = this.state.isMobile;
		const isDesktop = !isMobile;

		if (this.props.onlyDesktop && isMobile) {
			return <></>;
		}
		// if (this.props.onlyMobile && isDesktop)
		// return (<></>);
		if (this.props.hideOnMobile && isMobile) {
			return <></>;
		}

		const REGISTER_PRICE_ALERTS = Helper.bool(process.env.NEXT_PUBLIC_REGISTER_PRICE_ALERTS as string);

		if (!REGISTER_PRICE_ALERTS) return <></>;

		const priceAlert = this.props.priceAlert;

		if (priceAlert.indexOf('email') !== -1) {
			return <EmailConfirmation isDesktop={isDesktop} isMobile={isMobile} />;
		}

		return (
			<>
				<EmailConfirmationCheckbox isDesktop={isDesktop} />
			</>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlert: state.base.priceAlert,
	isBrowserSupportNotification: state.base.isBrowserSupportNotification,
	random: state.base.random,
	countryCode: state.base.countryCode,
});
export default connect(mapStateToProps, {})(Confirmation);

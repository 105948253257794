import { TFunction } from 'i18next';
import Head from 'next/head';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../locales/i18n';
import Helper from '../modules/Helper';
import NewMainArea from './Common/NewMainArea';
import NewsLetterForm from './Common/NewsLetterForm';
import VertTitle from './Common/VertTitle';
import FilterForm from './Hotels/FilterForm';
import KCFilterForm from './Hotels/KCFilterForm/KCFilterForm';
import MainLayout from './Layouts/MainLayout';

type HotelsProps = {
	slug: string;
	query?: any;
	dynamic?: boolean;
	useHeadline?: boolean;
	location?: string;
	cityName?: string;
	page?: any;
	hotelName?: string;
	isSigninCheckboxChecked: boolean;
	isUserLoggedIn: boolean;
	t: TFunction;
};
type HotelsState = {
	isVan: boolean;
	vertTitle: string;
	description: string;
	brandsDescription: string;
	h2description: string;
	metaDescription: string;
	newsletterSlogan: string;
	cityTitle: string;
	metaTitle: string;
	slogan: string;
	url: string;
	cityMetaDescription?: string;
	cityKeywords?: string;
	isMobile: boolean;
};
class Hotels extends React.Component<HotelsProps, HotelsState> {
	constructor(props: HotelsProps) {
		super(props);
		this.state = {
			isVan: false,
			vertTitle: this.props.t('mainArea.hotels.title'),
			brandsDescription: this.props.t('main.hotels.brandsDescription'),
			newsletterSlogan: this.props.t('main.hotels.newsletterSlogan'),
			description: this.props.t('metadata.hotels.description'),
			h2description: ``,
			metaDescription:
				this.props.page?.metaDescription ||
				'Compare cheap hotels on Search Hotel Prices, one of the best flight comparison websites. Find cheapest air fares, discounted flights, and travel deals. Sign up for free alerts. ',
			cityTitle: ``,
			metaTitle: this.props.page?.title || 'Search Hotel Prices',
			url: `https://searchhotelprices.com/${props.slug}${props.page?.lng ? `?lng=${props.page.lng}` : ''}`,
			slogan: this.props.t(`main.hotels.verticalSlogan`),
			isMobile: false,
		};
	}

	componentDidMount() {
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
		if (this.props.cityName) {
			this.setState({
				vertTitle: this.props.t('mainArea.hotels.dynamicCityTitle', { cityName: this.props.cityName }),
				brandsDescription: this.props.t('main.hotels.dynamicCityBrandsDescription', { cityName: this.props.cityName }),
				newsletterSlogan: this.props.t('main.hotels.dynamicCityNewsletterSlogan', { cityName: this.props.cityName }),
			});
		}
		if (this.props.hotelName) {
			this.setState({
				vertTitle: `Compare ${this.props.hotelName}`,
			});
		}
		if (this.props.page) {
			this.setState({
				vertTitle: this.props.page.headingOne,
				brandsDescription: this.props.page.headingTwo,
			});
		}
		const texts = this.props.query?.texts;
		if (texts) {
			this.setState({
				metaDescription: texts.metaDescription,
				metaTitle: texts.metaTitle,
				vertTitle: texts.h1,
				brandsDescription: texts.h2,
				newsletterSlogan: texts.newsletter,
			});
		}
	}

	checkWindowSize = () => {
		const width = window.innerWidth;
		this.setState({
			isMobile: width < 575,
		});
	};

	render() {
		const { isSigninCheckboxChecked, isUserLoggedIn } = this.props;

		const showNewsletterCriteria = !(isUserLoggedIn && isSigninCheckboxChecked);

		if (typeof window === 'undefined') {
			return null;
		}
		const width = window.innerWidth;
		const isMobile = width < 575 || this.state.isMobile;

		const isKc = Helper.isKayakCompareFlowFront() || Helper.isKayakCompareFlowBack();

		const nonKcForm = (
			<>
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="hotels-filter-form col-12 ">
							<div className="row">
								<div className="col-md" id="parent-filter">
									<VertTitle useHeadline={this.props.useHeadline} i18nKey={this.state.vertTitle} />
									<FilterForm />
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewMainArea brandsDescription={this.state.brandsDescription} />
			</>
		);
		const kcForm = (
			<>
				<VertTitle useHeadline={this.props.useHeadline} i18nKey={this.state.vertTitle} />
				<KCFilterForm brandsDescription={this.props.t('main.hotels.kcBrandsDescription')} />
			</>
		);

		const formToRender = !isMobile && isKc ? kcForm : nonKcForm;
		return (
			<MainLayout>
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
								{
										"@context": "https://schema.org",
										"@type": "Service",
										"name": "${this.state.metaTitle}",
										"description" : "${this.state.metaDescription}",
										"url" :"${this.state.url}",
										"image" : "https://searchhotelprices.com/images/block-background-cars.png"
								}`,
						}}
					/>
				</Head>
				<div id="main-area" className="hotels-container">
					{formToRender}
					<div id="image-curve">
						<div className="container">
							<div className="row " style={{ width: '100%' }}>
								<div className="col-lg-6">
									<p
										dangerouslySetInnerHTML={{
											__html: i18n.t('main.hotels.verticalSlogan'),
										}}
									/>
								</div>

								<div className="col-lg-6 d-flex justify-content-end" id="hotel-images">
									<img className="img-fluid" id="first-hotel-image" src="/images/hotels/img_3.webp" alt="Hotels" />
									<img className="img-fluid" id="second-hotel-image" src="/images/hotels/img_2.webp" alt="Hotels" />
									<img className="img-fluid" id="third-hotel-image" src="/images/hotels/img_1.webp" alt="Hotels" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{showNewsletterCriteria && (
					<NewsLetterForm>
						<h5
							dangerouslySetInnerHTML={{
								__html: this.state.newsletterSlogan,
							}}
						/>
					</NewsLetterForm>
				)}
			</MainLayout>
		);
	}
}
const mapStateToProps = (state: any) => ({
	isSigninCheckboxChecked: state.base.signinCheckbox,
	isUserLoggedIn: state.base.isUserLoggedIn,
});

export default withTranslation()(connect(mapStateToProps, null)(Hotels));
